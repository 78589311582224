<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :before-close="handleCancel"
    :destroy-on-close="true"
    width="700px"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="modalFrom"
      label-width="140px"
      size="small"
    >
      <el-form-item label="节点ID：" prop="nodeId" v-show="false">
        <el-input disabled v-model="form.nodeId"></el-input>
      </el-form-item>
      <el-form-item label="节点类型：" prop="nodeType" required>
        <el-radio-group v-model="form.nodeType" :disabled="isEdit">
          <el-radio :label="1">菜单</el-radio>
          <el-radio :label="2">操作</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="节点端口：" prop="nodePort" required>
        <el-radio-group v-model="form.nodePort">
          <el-radio :label="1">运营端</el-radio>
          <el-radio :label="2">企微端</el-radio>
          <el-radio :label="3">供应端</el-radio>
          <el-radio :label="4">企业端</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="节点名称：" prop="nodeName">
        <el-input disable v-model="form.nodeName"></el-input>
      </el-form-item>
      <el-form-item label="父级节点：" prop="parentId">
        <el-select-tree
          :style="{ width: '100%' }"
          placeholder="请选择内容"
          :data="menuTree"
          v-model="form.parentId"
          check-strictly
          :props="{ label: 'nodeName', children: 'subList', value: 'nodeId' }"
        >
          <span slot="option" slot-scope="{ data }">
            {{ data.nodeName }}
            <span v-if="data.nodePort === 1">- 运营端</span>
            <span v-if="data.nodePort === 2">- 企微端</span>
            <span v-if="data.nodePort === 3">- 供应端</span>
            <span v-if="data.nodePort === 4">- 企业端</span>
          </span>
        </el-select-tree>
      </el-form-item>
      <el-form-item
        required
        label="授权标识："
        prop="nodeCode"
        v-if="form.nodeType === 2"
      >
        <el-input v-model="form.nodeCode" placeholder="授权表示唯一不能重复"></el-input>
      </el-form-item>
      <el-form-item label="路由地址：" prop="path" v-if="form.nodeType === 1">
        <el-input v-model="form.path" />
      </el-form-item>
      <el-form-item
        label="微应用名称："
        prop="mircoName"
        v-if="form.nodeType === 1 && (form.nodePort === 1 || form.nodePort === 3)"
      >
        <el-input v-model="form.mircoName" />
      </el-form-item>
      <el-form-item
        label="微应用入口地址："
        prop="mircoEntry"
        v-if="(form.nodeType === 1) && (form.nodePort === 1 || form.nodePort === 3)"
      >
        <el-input v-model="form.mircoEntry" />
      </el-form-item>
      <el-form-item label="是否需要数据权限：" prop="isNeedDataPermission">
        <el-radio-group v-model="form.isNeedDataPermission">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否缓存：" prop="noCache" v-if="false">
        <el-radio-group v-model="form.noCache">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="是否在面包屑中隐藏："
        prop="hideBread"
        v-if="(form.nodeType === 1) && (form.nodePort === 1 || form.nodePort === 3 || form.nodePort === 4)"
      >
        <el-radio-group v-model="form.hideBread">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="隐藏路由：" prop="hidden" v-if="form.nodeType === 1">
        <el-radio-group v-model="form.hidden">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="重定向：" prop="redirect" v-if="false">
        <el-input v-model="form.redirect" />
      </el-form-item>
      <el-form-item
        label="图标："
        prop="icon"
        v-if="form.nodeType === 1 && form.parentId === '0'"
      >
        <el-input v-model="form.icon" />
      </el-form-item>
      <el-form-item label="排序：" prop="sort" v-if="form.nodeType === 1">
        <el-input v-model="form.sort" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="confirmLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { pick } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';

export default {
  name: 'EditMenu',
  mixins: [TableModalMinix],
  props: {
    menuTree: {
      type: Array,
      default: () => []
    }
  },
  data () {

    return {
      isEdit: false,
      defaultForm: {
        nodeId: '',
        nodeName: '',
        parentId: '',
        path: '',
        nodeType: 1,
        mircoName: '',
        mircoEntry: '',
        isNeedDataPermission: 0,
        dataPermission: '',
        redirect: '',
        noCache: 0,
        icon: '',
        sort: 1,
        hideBread: 0,
        hidden: 0,
        nodePort: 1
      },
      visible: false,
      title: '',
      form: {
        nodeId: '',
        nodeName: '',
        parentId: '',
        path: '',
        nodeCode: "",// 按钮权限唯一key
        nodeType: 1,
        mircoName: '',
        mircoEntry: '',
        isNeedDataPermission: 0,
        dataPermission: '',
        redirect: '',
        noCache: 0,
        icon: '',
        sort: 1,
        hideBread: 0,
        hidden: 0,
        nodePort: 1
      },
      url: {
        add: 'user-web-api/admin/permission/add',
        update: 'user-web-api/admin/permission/update',
        checkRepeat: 'user-web-api/admin/rank/checkName'
      },
      menuRules: {
        nodeName: [
          { required: true, message: '请输入节点名称', trigger: 'blur' },
          { min: 2, max: 20, message: '节点名称长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: '请选择父级节点', trigger: 'blur' },
        ],
      },
      buttonRules: {
        nodeName: [
          { required: true, message: '请输入节点名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: '请选择父级节点', trigger: 'blur' },
        ],
        nodeCode: [
          { required: true, message: '请输入授权标识', trigger: 'blur' },
          { min: 2, max: 50, message: '授权标识长度在 2 到 50 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    rules () {
      if (this.form.nodeType === 1) {
        return this.menuRules
      }
      return this.buttonRules

    }
  },
  watch: {
    "form.nodeType" () {
      this.$nextTick(() => {
        this.$refs.modalFrom.clearValidate()
      })
    }
  },
  create () { },
  methods: {
    add () {
      this.isEdit = false;
      this.visible = true;
    },
    edit (row) {
      this.isEdit = true;
      this.form = {
        ...this.form,
        ...pick(row, [
          'nodeId',
          'nodeType',
          'nodeName',
          'parentId',
          'path',
          'mircoName',
          'mircoEntry',
          'isNeedDataPermission',
          'noCache',
          'hideBread',
          'hidden',
          'icon',
          'redirect',
          'sort',
          'nodePort',
          'nodeCode'
        ])
      };
      this.visible = true;
    },
    resetForm () { },
    async handleOk () {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            const updateParams = pick(this.form, [
              'nodeId',
              'nodeType',
              'nodeName',
              'parentId',
              'path',
              'mircoName',
              'mircoEntry',
              'isNeedDataPermission',
              'noCache',
              'hideBread',
              'hidden',
              'icon',
              'redirect',
              'sort',
              'nodePort',
              'nodeCode'
            ]);
            await this.handleModalUpdate({ data: { ...updateParams } });
          } else {
            const addParams = pick(this.form, [
              'nodeType',
              'nodeName',
              'parentId',
              'path',
              'mircoName',
              'mircoEntry',
              'isNeedDataPermission',
              'noCache',
              'hideBread',
              'hidden',
              'icon',
              'redirect',
              'sort',
              'nodePort',
              'nodeCode'
            ]);
            console.log(addParams, 'addParams');
            await this.handleModalAdd({ data: { ...addParams } });
          }
          this.$refs.modalFrom.resetFields();
          this.$emit('modalFormOk');
        }
      });
    }
  }
};
</script>

<style scoped></style>
